import { createApi } from '@reduxjs/toolkit/query/react';
import { authApiClient, nonAuthApiClient } from './ascertis-api-client';

/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */

const axiosBaseQuery = async (args, api, extraOptions) => {
  const useNonAuthClient = args.public || false;
  const method = args.method ? args.method.toUpperCase() : 'GET';
  let requestParams = { url: args.url, method, params: args.params };
  if (args.body) {
    requestParams = { url: args.url, method, data: args.body };
  }
  if (args.headers) {
    requestParams.headers = args.headers;
  }

  try {
    let result;
    if (useNonAuthClient) {
      result = await nonAuthApiClient(requestParams);
    } else {
      result = await authApiClient(requestParams);
    }
    return { data: result.data, meta: { headers: result.headers } };
  } catch (axiosError) {
    return {
      error: axiosError.response?.data || 'Request error'
    };
  }
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  tagTypes: [
    'Settings',
    'System',
    'User',
    'Control',
    'Requirement',
    'Threat',
    'ActionItem',
    'Note',
    'ControlNote',
    'RequirementNote',
    'DocumentRequirement',
    'Report'
  ],
  endpoints: builder => ({
    // Session API methods
    signIn: builder.mutation({
      query: ({ params }) => ({ url: '/api/v1/sign_in', method: 'POST', body: params })
    }),
    signOut: builder.mutation({
      query: () => ({ url: '/api/v1/sign_out', method: 'DELETE', public: true })
    }),

    // Permissions API methods
    getPermissions: builder.query({
      query: () => ({ url: '/api/v1/permissions' })
    }),
    getSystemPermissions: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/permissions` })
    }),

    // Password API methods
    resetPassword: builder.mutation({
      query: ({ params }) => ({ url: '/api/v1/password', method: 'POST', body: params, public: true })
    }),
    getPasswordChangeUrl: builder.query({
      query: ({ token }) => ({ url: '/api/v1/password/edit', params: { reset_password_token: token }, public: true }),
      transformResponse: (response, meta) => meta.headers
    }),
    updatePassword: builder.mutation({
      query: ({ params, headers }) => ({ url: '/api/v1/password', method: 'PUT', body: params, headers, public: true })
    }),

    // Unlock API methods
    requestUnlock: builder.mutation({
      query: ({ params }) => ({ url: '/api/v1/unlock', method: 'POST', body: params, public: true })
    }),
    tokenUnlock: builder.query({
      query: ({ token }) => ({ url: `/api/v1/unlock`, params: { unlock_token: token }, public: true })
    }),

    // User API methods
    updateUser: builder.mutation({
      query: ({ params, headers }) => ({ url: '/api/v1', method: 'PUT', body: params, headers }),
      invalidatesTags: ['User']
    }),

    // Account Settings API Methods
    getAccountSettings: builder.query({
      query: () => ({ url: `/api/v1/settings` }),
      providesTags: ['Settings']
    }),
    updateAccountSettings: builder.mutation({
      query: ({ params }) => ({ url: '/api/v1/settings', method: 'PUT', body: params }),
      invalidatesTags: ['Settings']
    }),

    // User Agreement API Methods
    getUserAgreement: builder.query({
      query: () => ({ url: `/api/v1/user_agreement` })
    }),

    // Systems API methods
    getSystems: builder.query({
      query: () => ({ url: '/api/v1/systems' }),
      invalidatesTags: ['User']
    }),
    createSystem: builder.mutation({
      query: ({ params }) => ({
        url: `/api/v1/systems`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System', 'User']
    }),
    getSystem: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}` }),
      invalidatesTags: ['User']
    }),
    updateSystem: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'User']
    }),

    // System Users API methods
    getSystemUsers: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/users` }),
      providesTags: ['User']
    }),
    getSystemUser: builder.query({
      query: ({ systemId, userId }) => ({ url: `/api/v1/systems/${systemId}/users/${userId}` }),
      providesTags: ['User']
    }),
    createSystemUser: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/users`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['User']
    }),
    updateSystemUser: builder.mutation({
      query: ({ systemId, userId, params }) => ({
        url: `/api/v1/systems/${systemId}/users/${userId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['User']
    }),

    // Logo API methods
    getLogo: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/logo` })
    }),
    createLogo: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/logo`,
        method: 'POST',
        body: params
      })
    }),
    updateLogo: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/logo`,
        method: 'PUT',
        body: params
      })
    }),

    // Network Diagram API methods
    getNetworkDiagram: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/network_diagram` })
    }),
    createNetworkDiagram: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/network_diagram`,
        method: 'POST',
        body: params
      })
    }),
    updateNetworkDiagram: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/network_diagram`,
        method: 'PUT',
        body: params
      })
    }),

    // Equipment List API methods
    getEquipmentList: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/equipment_list` })
    }),
    createEquipmentList: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/equipment_list`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System']
    }),
    updateEquipmentList: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/equipment_list`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System']
    }),
    deleteEquipmentList: builder.mutation({
      query: ({ systemId }) => ({
        url: `/api/v1/systems/${systemId}/equipment_list`,
        method: 'DELETE'
      }),
      invalidatesTags: ['System']
    }),

    // Software List API methods
    getSoftwareList: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/software_list` })
    }),
    createSoftwareList: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/software_list`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System']
    }),
    updateSoftwareList: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/software_list`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System']
    }),
    deleteSoftwareList: builder.mutation({
      query: ({ systemId }) => ({
        url: `/api/v1/systems/${systemId}/software_list`,
        method: 'DELETE'
      }),
      invalidatesTags: ['System']
    }),

    // Controls API methods
    getControls: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/controls` }),
      providesTags: ['Control']
    }),
    getControl: builder.query({
      query: ({ systemId, controlId }) => ({ url: `/api/v1/systems/${systemId}/controls/${controlId}` }),
      providesTags: ['Control']
    }),

    // Control Implementation API methods
    getControlImplementation: builder.query({
      query: ({ systemId, controlId }) => ({ url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation` }),
      providesTags: ['Control']
    }),
    updateControlImplementation: builder.mutation({
      query: ({ systemId, controlId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'Control']
    }),

    // Control Requirements API methods
    getControlRequirements: builder.query({
      query: ({ systemId, controlId }) => ({ url: `/api/v1/systems/${systemId}/controls/${controlId}/requirements` }),
      providesTags: ['Control', 'Requirement']
    }),
    getControlRequirement: builder.query({
      query: ({ systemId, controlId, requirementId }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirements/${requirementId}`
      }),
      providesTags: ['Control', 'Requirement']
    }),
    updateControlRequirement: builder.mutation({
      query: ({ systemId, controlId, requirementId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirements/${requirementId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'Control', 'Requirement', 'Threat', 'ActionItem']
    }),
    bulkUpdateControlRequirements: builder.mutation({
      query: ({ params }) => ({
        url: `/api/v1/batch`,
        method: 'POST',
        body: { requests: params }
      }),
      invalidatesTags: ['System', 'Control', 'Requirement', 'Threat', 'ActionItem']
    }),

    // Threats API methods
    getThreats: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/threats` }),
      providesTags: ['Threat']
    }),
    updateThreat: builder.mutation({
      query: ({ systemId, threatId, params }) => ({
        url: `/api/v1/systems/${systemId}/threats/${threatId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'Threat']
    }),

    // Capabilities API methods
    getCapabilities: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/capabilities` })
    }),

    // Action Plan API methods
    getActionPlan: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/action_plan` })
    }),
    getActionItems: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/action_plan/action_items` }),
      providesTags: ['ActionItem']
    }),
    getActionItem: builder.query({
      query: ({ systemId, actionItemId }) => ({ url: `/api/v1/systems/${systemId}/action_plan/action_items/${actionItemId}` })
    }),
    updateActionItem: builder.mutation({
      query: ({ systemId, actionItemId, params }) => ({
        url: `/api/v1/systems/${systemId}/action_plan/action_items/${actionItemId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'ActionItem']
    }),
    uploadActionItems: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/action_plan/upload`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System', 'ActionItem']
    }),

    // Notes API methods
    getSystemNotes: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/notes` }),
      providesTags: ['Note']
    }),

    getControlNotes: builder.query({
      query: ({ systemId, controlId }) => ({ url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation_notes` }),
      providesTags: ['ControlNote']
    }),
    createControlNote: builder.mutation({
      query: ({ systemId, controlId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation_notes`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System', 'ControlNote']
    }),
    getControlNote: builder.query({
      query: ({ systemId, controlId, noteId }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation_notes/${noteId}`
      }),
      providesTags: ['ControlNote']
    }),
    updateControlNote: builder.mutation({
      query: ({ systemId, controlId, noteId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation_notes/${noteId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'ControlNote']
    }),
    deleteControlNote: builder.mutation({
      query: ({ systemId, controlId, noteId }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/implementation_notes/${noteId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['System', 'ControlNote']
    }),


    getRequirementNotes: builder.query({
      query: ({ systemId, controlId }) => ({ url: `/api/v1/systems/${systemId}/controls/${controlId}/requirement_notes` }),
      providesTags: ['RequirementNote']
    }),
    createRequirementNote: builder.mutation({
      query: ({ systemId, controlId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirement_notes`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['System', 'RequirementNote']
    }),
    getRequirementNote: builder.query({
      query: ({ systemId, controlId, noteId }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirement_notes/${noteId}`
      }),
      providesTags: ['RequirementNote']
    }),
    updateRequirementNote: builder.mutation({
      query: ({ systemId, controlId, noteId, params }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirement_notes/${noteId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['System', 'RequirementNote']
    }),
    deleteRequirementNote: builder.mutation({
      query: ({ systemId, controlId, noteId }) => ({
        url: `/api/v1/systems/${systemId}/controls/${controlId}/requirement_notes/${noteId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['System', 'RequirementNote']
    }),

    // Documents API methods
    getDocuments: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/documents` })
    }),
    createDocument: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/documents`,
        method: 'POST',
        body: params
      })
    }),
    updateDocument: builder.mutation({
      query: ({ systemId, documentId, params }) => ({
        url: `/api/v1/systems/${systemId}/documents/${documentId}`,
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['DocumentRequirement']
    }),
    getDocumentControls: builder.query({
      query: ({ systemId, documentId }) => ({ url: `/api/v1/systems/${systemId}/documents/${documentId}/controls` })
    }),
    getDocumentRequirements: builder.query({
      query: ({ systemId, documentId }) => ({ url: `/api/v1/systems/${systemId}/documents/${documentId}/requirements` }),
      providesTags: ['DocumentRequirement']
    }),

    // Reports API methods
    getReports: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/reports` }),
      providesTags: ['Report']
    }),
    createReport: builder.mutation({
      query: ({ systemId, params }) => ({
        url: `/api/v1/systems/${systemId}/reports`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Report']
    }),

    // Process API methods
    getProcesses: builder.query({
      query: ({ systemId }) => ({ url: `/api/v1/systems/${systemId}/processes` })
    }),
    getProcess: builder.query({
      query: ({ systemId, processId }) => ({ url: `/api/v1/systems/${systemId}/processes/${processId}` })
    }),
    updateProcess: builder.mutation({
      query: ({ systemId, processId, params }) => ({
        url: `/api/v1/systems/${systemId}/processes/${processId}`,
        method: 'PUT',
        body: params
      })
    }),

    // Solution Partners API methods
    getSolutionPartners: builder.query({
      query: ({}) => ({ url: `/api/v1/solution_partners` })
    }),
    createSolutionPartner: builder.mutation({
      query: ({ params }) => ({
        url: `/api/v1/solution_partners`,
        method: 'POST',
        body: params
      })
    }),

    // System Provision Partners API methods
    getSystemProvisionRequests: builder.query({
      query: ({}) => ({ url: `/api/v1/provision_requests` })
    }),
    createSystemProvisionRequest: builder.mutation({
      query: ({ params }) => ({
        url: `/api/v1/provision_requests`,
        method: 'POST',
        body: params
      })
    })

  })
})

export const {
  // Sessions
  useSignInMutation,
  useSignOutMutation,
  // Permissions
  useGetPermissionsQuery,
  useGetSystemPermissionsQuery,
  // Passwords
  useResetPasswordMutation,
  useGetPasswordChangeUrlQuery,
  useUpdatePasswordMutation,
  // Unlocks
  useRequestUnlockMutation,
  useTokenUnlockQuery,
  // User
  useUpdateUserMutation,
  // User Agreement
  useGetUserAgreementQuery,
  // Account Settings
  useGetAccountSettingsQuery,
  useUpdateAccountSettingsMutation,
  // Systems
  useGetSystemsQuery,
  useCreateSystemMutation,
  useGetSystemQuery,
  useUpdateSystemMutation,
  // System Users
  useGetSystemUsersQuery,
  useGetSystemUserQuery,
  useCreateSystemUserMutation,
  useUpdateSystemUserMutation,
  // Logo
  useGetLogoQuery,
  useCreateLogoMutation,
  useUpdateLogoMutation,
  // Network Diagram
  useGetNetworkDiagramQuery,
  useCreateNetworkDiagramMutation,
  useUpdateNetworkDiagramMutation,
  // Equipment List
  useGetEquipmentListQuery,
  useCreateEquipmentListMutation,
  useUpdateEquipmentListMutation,
  useDeleteEquipmentListMutation,
  // Software List
  useGetSoftwareListQuery,
  useCreateSoftwareListMutation,
  useUpdateSoftwareListMutation,
  useDeleteSoftwareListMutation,
  // Controls
  useGetControlsQuery,
  useGetControlQuery,
  // Control Implementations
  useGetControlImplementationQuery,
  useUpdateControlImplementationMutation,
  // Control Requirements
  useGetControlRequirementsQuery,
  useGetControlRequirementQuery,
  useUpdateControlRequirementMutation,
  useBulkUpdateControlRequirementsMutation,
  // Threats
  useGetThreatsQuery,
  useUpdateThreatMutation,
  // Capabilities
  useGetCapabilitiesQuery,
  // Action Plan
  useGetActionPlanQuery,
  useGetActionItemsQuery,
  useGetActionItemQuery,
  useUpdateActionItemMutation,
  useUploadActionItemsMutation,
  // Notes
  useGetSystemNotesQuery,
  useGetControlNotesQuery,
  useCreateControlNoteMutation,
  useGetControlNoteQuery,
  useUpdateControlNoteMutation,
  useDeleteControlNoteMutation,
  useGetRequirementNotesQuery,
  useCreateRequirementNoteMutation,
  useGetRequirementNoteQuery,
  useUpdateRequirementNoteMutation,
  useDeleteRequirementNoteMutation,
  // Documents
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  useGetDocumentControlsQuery,
  useGetDocumentRequirementsQuery,
  // Reports
  useGetReportsQuery,
  useCreateReportMutation,
  // Processes
  useGetProcessesQuery,
  useGetProcessQuery,
  useUpdateProcessMutation,
  // Solution Partners
  useGetSolutionPartnersQuery,
  useCreateSolutionPartnerMutation,
  // System Provision Requests
  useGetSystemProvisionRequestsQuery,
  useCreateSystemProvisionRequestMutation,

} = apiSlice;

/* eslint-enable arrow-parens */
/* eslint-enable object-curly-newline */
